import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/system';

export default function Marquee() {

    const message = " Franco's Services   ***   ";
    const repeatedMessage = message.repeat(13);
    const marqueeAnimation = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  `;
    return (
    <Box
    sx={{
        width: '100%',
        overflow: 'hidden', // Esconde el contenido que sale del área visible
        whiteSpace: 'nowrap', // Evita que el texto se envuelva en la siguiente línea
        boxSizing: 'border-box',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'inline-block',

          animation: `${marqueeAnimation} 30s linear infinite`, // Aplica la animación
        }}
      >
        <Typography variant="h6" sx={{ display: 'inline-block', whiteSpace: 'nowrap' ,
           fontSize: 'clamp(1rem, 1vw, 2rem)',
        }}>
        {repeatedMessage} {repeatedMessage}
        </Typography>
      </Box>
    </Box>
  );
}
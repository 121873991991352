import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Plus, X } from "@phosphor-icons/react";
import CButton from './circular';

const ExpandableText = ({ title, detailText }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        border: '2px solid rgba(218, 197, 167, 0.15)',
        borderRadius: '8px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        color: 'inherit',
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer',
          width: '100%',
          justifyContent: 'space-between',
        }}
        onClick={handleToggle}
      >
        <Typography variant="h5" sx={{ flex: 1,
            fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',
         }}>
          {title}
        </Typography>
        <CButton>
          {expanded ? <X size={24} /> : <Plus size={24} />}
        </CButton>
      </Box>
      {expanded && (
        <Typography sx={{ mt: '1rem', textAlign: 'left', fontSize: 'clamp(1rem, 1vw, 2rem)', }}>
          {detailText}
        </Typography>
      )}
    </Box>
  );
};

export default ExpandableText;

import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const BlogHeader = () => {
  return (
    <Box sx={{ textAlign: 'center', mb: '2rem', mt: '10rem' }}>
      <Typography variant="h1" sx={{ fontSize: 'clamp(2rem, 5vw, 3rem)' }}>
        Blog
      </Typography>
      <Paper sx={{ p: '1rem', mt: '1rem', maxWidth: '600px', mx: 'auto' }}>
        <Typography variant="h6" sx={{ fontSize: 'clamp(1rem, 1.4vw, 1rem)' }}>
          At Franco's Services, we take immense pride in presenting unparalleled cleanliness assistance that metamorphoses residences and workplaces into attractive, welcoming environments. Our unyielding commitment to splendid work and client gratification is what distinguishes us.
        </Typography>
      </Paper>
    </Box>
  );
};

export default BlogHeader;

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image }) => {
  // URL base del sitio
  const baseUrl = 'https://francos-services.com';
  // URL actual del sitio
  const url = typeof window !== 'undefined' ? window.location.href : baseUrl;

  // Asegúrate de que image sea una URL completa
  const fullImageUrl = image.startsWith('http') ? image : `${baseUrl}${image}`;

  return (
    <Helmet>
      {/* Título de la página */}
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={description} />
      
      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImageUrl} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image:alt" content={description} />
    </Helmet>
  );
};

export default SEO;

import { Container, Box, } from "@mui/system";
import { CardMedia, Card, Typography, } from '@mui/material';
import { ArrowDown } from "@phosphor-icons/react";
import logo from '../img/logo.png';
import Marquee from "./cta";
import { alpha } from '@mui/system';
import StepSection from "./stepleft";
import CButton from "./circular";
import StepSec from "./step";
import StepSec4 from "./step4";
import StepSec3 from "./stepleft3";
import CommentsLayout from "./Gcoments";
import RContainer from "./Imgcont";
import Rabout from "./about";

export default function Main (){
    return(
        <Container sx={{ display: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
            width: '100%',
            height: '100%',
            }}           
            >
                <Box
                sx={{ mt: '10rem',

                }}>
            <Card elevation={0}>
                    <CardMedia 
                        component="img"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '500px', // Ajusta la altura máxima si es necesario
                            objectFit: 'cover', // Mantiene la proporción de la imagen
                          }}
                        image={ logo }
                        alt="francos services"
                    /></Card> 
                    </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',height: '100%'}} >
                <Typography variant="h1"
                sx={{
                    fontSize: 'clamp(2rem, 10vw, 20rem)',
                  }}
                >Housekeeping</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="subtitle1"
            sx={{
                fontSize: 'clamp(1.5rem, 1vw, 1rem)',
              }}
            >The best cleaning service in Maryland, Virginia & DC!</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <Box m='1rem'> <CButton color = 'inherit' > <ArrowDown p='0.5rem'/> </CButton></Box>
            </Box>
            <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <Typography variant="subtitle1"
                sx={{
                    fontSize: 'clamp(1.5rem, 1vw, 1rem)',
                }}
                >The Process</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="h3"
            sx={{
                fontSize: 'clamp(1rem, 5vw, 6rem)',
            }}
            >For impeccable cleaning:</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="h2" sx={{ fontStyle: 'italic',
                    fontFamily: 'Gambetta, sans-serif', 
                    fontSize: 'clamp(2rem, 7vw, 6rem)',
            }}>in 5 steps</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="subtitle" sx={{
                    fontSize: 'clamp(1.5rem, 1vw, 1rem)',
                    width: '50%',
                    textAlign: 'center'
            }}>We have perfected our cleaning process to ensure exceptional results with every visit.</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <Box m='1rem'> <CButton color = 'inherit' > <ArrowDown p='0.5rem'/> </CButton></Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                < StepSection number={1}/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <StepSec number={2}></StepSec>
            
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <StepSec3 
            number={3} 
            title="Space Preparation" 
            description="Before we begin, our team makes sure to protect your belongings and furniture, covering sensitive areas and organizing the space for efficient cleaning. We use high quality, ecological and safe cleaning products for your family and pets."
            />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <StepSec4 
            number={4} 
            title="Thorough Cleaning" 
            description="Our team of professionals is dedicated to cleaning every corner of your home, with adaptable services to suit various needs, offering recurring weekly, biweekly and monthly cleanings, individual sessions or even more regular visits tailored to hosts, ensuring maximum flexibility for meet your specific requirements."
            />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <StepSec3 
            number={5} 
            title="Revision" 
            description="Once the cleaning is complete, we perform a thorough check to make sure everything is in perfect condition."
            />
            </Box>
            <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', justifyContent: 'center',padding: 5}} >
            <Typography variant="h3"
            sx={{fontStyle: 'italic',
                fontSize: 'clamp(2rem, 5vw, 6rem)',
            }}
            >What my </Typography>
            <Typography variant="h3"
            sx={{fontStyle: 'italic',
                fontFamily: 'Gambetta, sans-serif', 
                fontSize: 'clamp(2rem, 5vw, 6rem)',
            }}
            >clients say</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <Typography variant="subtitle1"
                sx={{ 
                    fontSize: 'clamp(1.5rem, 1vw, 1rem)',
                }}
                >See what my clients have to say about working with us.</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <CommentsLayout></CommentsLayout>    
            </Box> 
            <RContainer/>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Rabout></Rabout>    
            </Box>
            <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>      
        </Container>
    );
}
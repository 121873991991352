import React from 'react';
import { Button, Box } from '@mui/material';
import { InstagramLogo, TiktokLogo, FacebookLogo, WhatsappLogo, ArrowRight } from '@phosphor-icons/react'; // Asegúrate de importar los íconos correctamente

const socialButtons = [
  { logo: <InstagramLogo size={23} />, name: 'Instagram', href: 'https://www.instagram.com/francosservices11/' },
  { logo: <TiktokLogo size={23} />, name: 'TikTok', href: 'https://www.tiktok.com/@francosservices11?lang=en' },
  { logo: <FacebookLogo size={23} />, name: 'Facebook', href: 'https://www.facebook.com/profile.php?id=61563120154820' },
  { logo: <WhatsappLogo size={23} />, name: 'WhatsApp', href: 'https://wa.me/+17372984421' },
];

const SocialButton = ({ logo, name, href }) => (
  <Button
    component="a" // Cambia el componente a un enlace
    href={href} // Usa el enlace proporcionado
    target="_blank" // Abre el enlace en una nueva pestaña
    rel="noopener noreferrer" // Mejora la seguridad al abrir en una nueva pestaña
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: 'inherit',
      px: 2,
      py: 1,
      flexBasis: 'calc(30% - 1rem)',
      width: '100%',
      borderRadius: '8px',
      textTransform: 'none',
      transition: 'all 0.3s ease',
      '&:hover': {
        '& .arrow-icon': {
          transform: 'translate(10px, -10px) rotate(-45deg)',
        },
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {logo}
      {name}
    </Box>
    <ArrowRight size={24} className="arrow-icon" />
  </Button>
);

export default function SocialButtons() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {socialButtons.map((social, index) => (
        <SocialButton key={index} {...social} />
      ))}
    </Box>
  );
}

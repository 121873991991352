import React from 'react';
import { Card, CardContent, CardMedia, Typography, Paper } from '@mui/material';

const BlogCard = ({ image, title, description }) => {
  return (
    <Paper elevation={3} sx={{ p: '1rem', height: '100%' }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CardMedia
          component="img"
          image={image}
          alt={title}
          sx={{ width: '100%',  height: 'auto', maxHeight: '200px', objectFit: 'cover', }}
        />
        <CardContent color = 'inherit'  sx={{ width: '100%',   }}>
          <Typography variant="h6" align="left" sx={{ mb: '0.5rem', fontSize: 'clamp(1rem, 1.4vw, 1rem)' }}>
            {title}
          </Typography>
          <Typography variant="body2" align="center" sx={{ fontSize: 'clamp(0.5rem, 0.8vw, 0.7rem)' }} >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default BlogCard;

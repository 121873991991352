import { Container, Box, } from "@mui/system";
import { CardMedia, Card, Typography, } from '@mui/material';
import { ArrowDown } from "@phosphor-icons/react";

import Marquee from "./cta";
import { alpha } from '@mui/system';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CButton from "./circular";
import { useMediaQuery } from '@mui/material';
import Sv from '../img/services.jpg';
import Sv1 from '../img/services (1).png';
import Sv2 from '../img/services (2).png';
import Sv3 from '../img/services (3).png';
import Sv4 from '../img/services (4).png';
import Sv5 from '../img/services (5).png';
import Sv6 from '../img/services (6).png';
import Sv7 from '../img/services (7).png';
import Sv8 from '../img/services (8).png';
import Sv9 from '../img/services (9).png';
import Sv10 from '../img/services (10).png';


export default function Sercices (){

  // Detectar el tamaño de la pantalla
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('md'));

  // Determinar el número de columnas basado en el tamaño de la pantalla
  const cols = isSmallScreen || isMediumScreen ? 1 : 2;

    return(
        <Container sx={{ display: 'row', alignItems: 'center', justifyContent: 'center'}}>
           
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',height: '100%', mt:'10rem'}} >
                <Typography variant="h1"
                sx={{
                    fontSize: 'clamp(2rem, 10vw, 20rem)',
                  }}
                >Housekeeping</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <Typography variant="subtitle1"
            sx={{
                fontSize: 'clamp(1.5rem, 1vw, 1rem)',
              }}
            >The best cleaning service in Maryland, Virginia & DC!</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <Box m='1rem' sx={{ display: 'flex', alignItems: 'center' }} > <CButton color = 'inherit' > <ArrowDown p='0.5rem'/> </CButton>
                <Typography sx={{ ml: '0.5rem' }}> My Services </Typography>
                 </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
            width: '100%',
            height: '100%',
            }}           
            >
                <Box
                sx={{ mt: '10rem',

                }}>
            <Card>
                    <CardMedia 
                        component="img"
                        width= '100%'
                        height= 'auto'
                        maxHeight= '500px' // Ajusta la altura máxima si es necesario
                        objectFit= 'cover'
                        image={ Sv }
                        alt="francos services"
                    /></Card> 
                    </Box>
            </Box>
            <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                <ImageList cols={cols} sx={{ width: '100%', height: '100%',
                 }}>
    
      {itemData.map((item) => (
        <ImageListItem
         key={item.img}>
          <img
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            sx={{
            
            bgcolor:  alpha('#CCCCCC', 0.05),
            border: '2px solid rgba(218, 197, 167, 0.15)',
            }}
            titleTypographyProps={{
                fontFamily: 'SatoshiL, serif', //
                color: '#DAC5A7', // 
               
              }}
            />
        </ImageListItem>
      ))}
    </ImageList>

                </Box>
                <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>
            </Container>
    );
}

const itemData = [
    {
      img: Sv1,
      title: 'Regular Cleaning',
      author: '@bkristastucchio',
 
      featured: true,
    },
    {
      img: Sv2,
      title: 'Residential Cleaning',
      author: '@rollelflex_graphy726',
    },
    {
      img: Sv3,
      title: 'Cleaning for Airbnb',
      author: '@helloimnik',
    },
    {
      img: Sv4,
      title: 'Deep Cleaning',
      author: '@nolanissac',
      cols: 2,
    },
    {
      img: Sv5,
      title: 'Moving Cleaning',
      author: '@hjrc33',
      cols: 2,
    },
    {
      img: Sv6,
      title: 'Exterior Cleaning',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Sv7,
      title: 'Commercial Cleaning',
      author: '@tjdragotta',
    },
    {
      img: Sv8,
      title: 'Daycare Cleaning',
      author: '@katie_wasserman',
    },
    {
      img: Sv9,
      title: 'Personalized Cleaning',
      author: '@katie_wasserman',
    },
    {
      img: Sv10,
      title: 'Post-construction Cleaning',
      author: '@katie_wasserman',
    },
    
  ];
import { Box, Container } from '@mui/system';
import { Button, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import ExpandableText from './expandiblebutton';
import { Paper } from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomText from './cancel';
import { Dot } from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom'; 
import Marquee from "./cta";
import { alpha } from '@mui/system';


const theme = createTheme({
    palette: {
      ochre: {
        main: '#DAC5A7',
        light: '#E9DB5D',
        dark: '#EDD6B6',
        contrastText: '#242105',
      },
      
    },
    typography: {
      fontFamily: 'SatoshiL, Arial',
      
    },
  });

export default function Faq () {

    const info = [
        { title: 'What are your rates?' ,  detailText: 'Our rates vary depending on the type of service and frequency of cleaning. We offer custom quotes based on the size of your space and the services required. Contact us for a free, detailed quote.' },
        { title: 'When and how do I make a payment?' ,  detailText: 'Payment must be made at the time of service. We accept several forms of payment, including cash, Zelle, and transfer. 20 minutes before completing service at your property, our cleaners must receive payment.' },
        { title: 'Should I tip?' ,  detailText: 'Tips are accepted and greatly appreciated.' },
        { title: 'What services do you offer?' ,  detailText: 'Recurring cleaning: weekly, biweekly, monthly. Deep Cleaning: More detailed cleaning for home/office. Entry and exit cleaning Custom Cleaning: We work with you to determine your specific needs.' },
        { title: 'Are they insured and licensed?' ,  detailText: 'Yes, we are fully insured and have all necessary licenses to operate in Washington, DC. The safety and peace of mind of our clients are our top priorities.' },
        { title: 'What are the prices of the service?' ,  detailText: 'Our rates vary depending on the type of service and frequency of cleaning. We offer custom quotes based on the size of your space and the services required. Contact us for a free, detailed quote.' },
        { title: 'How long will the cleanup take?' ,  detailText: 'The time required for cleaning depends on the size of your space and the type of service requested. At the time of quote, we will provide you with an estimate of the time required.' },
        { title: 'How can I schedule an appointment?' ,  detailText: 'You can schedule an appointment by calling us, emailing us, or filling out the online form on our website. We will be happy to coordinate a schedule that suits your needs.' },
        { title: 'What cleaning services do you offer?' ,  detailText: 'We offer a wide range of cleaning services to meet all your needs. These include:  Residential Cleaning: Keep your home spotless with our regular or one-time cleaning services. Office Cleaning: Create a clean and professional environment for your employees and clients. Deep Cleaning: Perfect for a thorough cleaning of all areas of your home or office, including those hard-to-reach places. Move-In/Move-Out Cleaning: Make your move easier with our cleaning services before or after your relocation. Airbnb Cleaning: Ensure your guests have a flawless experience with our dedicated cleaning services for Airbnb properties. Recurring Cleanings: We customize the frequency of our visits to keep your space consistently clean. Customized Cleanings: Tailor our services to meet your specific cleaning needs and preferences. We tailor our services to your specific needs to ensure an impeccably clean environment.' },
        
      ];
      const navigate = useNavigate();

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          
          behavior: 'smooth', // Desplazamiento suave
        });
      };
      const handleClick = () => {
        scrollToTop();
        navigate('/contactus');
      };

    return(
        <Container sx={{ display: 'row', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{
             display: 'flex', 
              gap: 2, 
              mt: '10rem', 
              mb: '5rem',
            width: '100%',
        flexDirection: 'column',
         alignItems: 'center',
         }}>
      {info.map((info, index) => (
        <ExpandableText key={index} {...info} />
      ))}
    </Box>
    <Paper sx={{ mb: '5rem', p: '2rem' }}>
      {/* Título */}
      <Typography variant="h8" textTransform={'uppercase'} gutterBottom
       sx={{  fontSize: 'clamp(0.4rem, 1vw, 1rem)',}}
      >
      <strong>warning</strong>
      </Typography>

      {/* Subtítulo */}
      <Typography variant="h4" gutterBottom
      sx={{  fontSize: 'clamp(1rem, 5vw, 2rem)',}}
      >
      Cancellation and Rescheduling Fee Policy
      </Typography>

      {/* Texto */}
      <Typography variant="body1" gutterBottom
      sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
      >
      Cancellation and Rescheduling Fee Policy
      </Typography>

      <Typography variant="body1" gutterBottom  sx={{  mt: '1rem',
        fontSize: 'clamp(1rem, 1vw, 2rem)',
      }}>
      At Franco's Services we understand that plans can change. Therefore, we have established a clear cancellation and rescheduling policy to ensure flexibility and transparency for our clients. Below, we detail our fees and conditions:
      </Typography>

      <Typography variant="body1" gutterBottom sx={{  mt: '1rem',  fontSize: 'clamp(1rem, 1vw, 2rem)',}}>
      Cancellations
      </Typography>
      <Divider/>
      <CustomText
        text1="1. Cancellation with more than 48 hours' notice:"
        texto2="If you cancel your service with more than 48 hours' notice, a $50 cancellation fee will be charged."
      />
       <CustomText
        text1="2.Cancellation between 24 and 48 hours before the service:"
        texto2="If you cancel your service between 24 and 48 hours before the scheduled time, 50% of the total service cost will be charged."
      />
             <CustomText
        text1="3.Cancellation with less than 24 hours' notice:"
        texto2="If you cancel your service with less than 24 hours' notice, 100% of the total service cost will be charged."
      />
         <Typography variant="body1" gutterBottom sx={{  mt: '1rem',  fontSize: 'clamp(1rem, 1vw, 2rem)',}}>
         Rescheduling
      </Typography>
      <Divider/>
      <CustomText
        text1="1.Rescheduling with more than 24 hours' notice:"
        texto2="If you need to reschedule your service with more than 24 hours' notice, there will be no additional charge. Rescheduling is free."
      />
       <CustomText
        text1="2.Rescheduling with less than 24 hours' notice:"
        texto2="If you need to reschedule your service with less than 24 hours' notice, a fee of 50% of the total service cost will be charged."
      />

      <Typography variant="body1" gutterBottom sx={{  mt: '1rem',  fontSize: 'clamp(1rem, 1vw, 2rem)',}}>
      Service Cannot Be Performed
      </Typography>
      <Divider/>
      <CustomText
        text1="1.Inability to perform the service due to client-related reasons:"
        texto2="If our team arrives at your property at the scheduled time and cannot perform the cleaning due to client-related circumstances (such as lack of access to the property), 100% of the total service cost will be charged."
      />
       <Box sx={{ mt: '1rem', width:'100%' }}>
       <Typography variant="body1" gutterBottom
      sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
      >
        Policy Application Examples
      </Typography>
      <Divider/>

        <List>
        <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Cancellation 72 hours before the service: $50 cancellation fee.'
      sx={{ ml: 1 }} // Agrega un margen izquierdo para separar el texto del icono
    />
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Cancellation 36 hours before the service: 50% of the total service cost.'
      sx={{ ml: 1 }}
    />
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Cancellation 12 hours before the service: 100% of the total service cost.'
      sx={{ ml: 1 }}
    />
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Rescheduling 30 hours before the service: No additional charge.'
      sx={{ ml: 1 }}
    />
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Rescheduling 12 hours before the service: 50% of the total service cost.'
      sx={{ ml: 1 }}
    />
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Dot size={24} />
    <ListItemText 
      primary='Inability to perform the service: 100% of the total service cost.'
      sx={{ ml: 1 }}
    />
  </Box>
</ListItem>

      </List>
      </Box>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: '2rem', // Ajusta el margen superior según sea necesario
      }}
      >
      <ThemeProvider theme={theme}>
            <Button
            sx={{
              fontSize: {
                
                sm: '1rem',    // Tamaño de fuente mediano para pantallas medianas
                md: '0.9rem',  // Tamaño de fuente más grande para pantallas grandes
              },
              p:0.2,
              m:1,
              width: {
                
                sm: '50%',  // Ancho estándar para pantallas medianas
                md: '100%',  // Ancho más grande para pantallas grandes
              },
            }}
            variant="contained" color="ochre"
            onClick={handleClick}
            >GET STARTED <ArrowUpRight size={24} /></Button>
            </ThemeProvider>
            </Box>
    </Paper>
    <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>
    </Container>
    );
};
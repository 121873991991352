import * as React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../img/logo1.png';

export default function RContainer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // En pantallas pequeñas, apilar verticalmente
          sm: 'row'    // En pantallas medianas y grandes, alinear en fila
        },
        width: '100%',
        height: 'auto',
        maxHeight: '300px', // Ajusta la altura máxima si es necesario
        objectFit: 'cover',
        padding: 2,
      }}
    >
         {/* Lado Izquierdo con la imagen */}
      <Box
        sx={{
          flex: 1,              // Ocupa el 50% del ancho disponible
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
      >
        <img 
          src={ logo }  // Ruta a la imagen
          alt="Limpieza" 
          style={{ width: '100%', height: 'auto', maxWidth: '400px', objectFit: 'cover', }} // Ajusta la imagen al contenedor
        />
      </Box>
    
      {/* Lado derecho con el título */}
      <Box
        sx={{
          flex: 1,              // Ocupa el 50% del ancho disponible
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" 
         sx={{
            fontStyle: 'italic',
            fontSize: 'clamp(2rem, 5vw, 20rem)',
          }}
        >
        Franco's Services
        </Typography>
      </Box>

     </Box>
  );
}

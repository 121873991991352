import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system';
import { Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CButton from './circular';

function StepSection({ number }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%', // Ajusta según sea necesario
        position: 'relative',
        
      }}
    >
              {/* Parte derecha vacía */}
      <Box
        sx={{
          width: '40%', // Ocupa el 40% del ancho
          backgroundColor: 'transparent', // Cuadro vacío
          height: '100%',
          flex: {
            sm: '0',
            md: '1',
          },
        }}
      />
      {/* Parte central con línea y número */}
      <Box
        sx={{
          width: {
            xs: '10%',
            sm: '20%',
          },
          display: 'flex',
          height: '25rem',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {/* Línea superior */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea
          }}
        />

        {/* Número en el centro */}
        <CButton>
        <Typography
          variant="h4"
          sx={{
            zIndex: 1, // Asegura que el número esté encima de la línea
            
            padding: '0 0.5rem',
            margin: 'auto', // Centra el número verticalmente
          }}
        >
          {number}
        </Typography>
        </CButton>
        {/* Línea inferior */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea
          }}
        />
      </Box>


            {/* Parte izquierda con texto */}
            <Box
        sx={{
          width:{
            sm: '90%',
            md: '40%',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          
        }}
      >
       <Paper
        sx={{
          flex: 1,
          backgroundColor: alpha('#CCCCCC', 0.05),
          height: '100%',
          width:'80%',
          display: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          
        }}
      >
        <Typography variant="h5" textTransform={'uppercase'} 
        sx={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
        >
        Personalized Evaluation and Quote
        </Typography>
        <Typography variant="subtitle1"
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
        We begin with a detailed evaluation of your space to understand your specific needs. We offer a personalized quote based on the size of your home or office and the services required. This step ensures that you receive a clear and transparent offer.
        </Typography>
        <List >
        <ListItem>
        <ListItemIcon sx={{ minWidth: '5%',}}>
          <CircleIcon sx={{ fontSize:"40%" }}/>
        </ListItemIcon>
        <ListItemText primary="We get to know each other better" />
      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ minWidth: '5%',}}>
          <CircleIcon sx={{ fontSize:"40%" }}/>
        </ListItemIcon>
        <ListItemText primary="Determine how I can best assist you" />
      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ minWidth: '5%',}}>
          <CircleIcon sx={{ fontSize:"40%" }}/>
        </ListItemIcon>
        <ListItemText primary="Understand the goals you have for your care" />
      </ListItem>
      
        </List>
      </Paper>
      </Box>

    </Box>
  );
}

export default StepSection;

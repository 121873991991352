import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { CardMedia, Card, Typography, Paper, TextField, Button } from '@mui/material';
import logo from '../img/logo1.png';
import SocialButtons from './socialbutton.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const theme = createTheme({
  palette: {
    ochre: {
      main: '#DAC5A7',
      light: '#E9DB5D',
      dark: '#EDD6B6',
      contrastText: '#242105',
    },
  },
});

function Contactus() {
  const [messageSent, setMessageSent] = useState(null);
  
  const formId = "7W0b32xxN";
  const formSparkUrl = `https://submit-form.com/${formId}`;

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    setMessageSent(null); // Resetea el estado del mensaje enviado antes de enviar el formulario

    try {
      const response = await axios.post(formSparkUrl, data);
      if (response.status === 200) {
        setMessageSent({ text: 'Message sent successfully!', color: 'green' });
        reset(); // Limpia los campos del formulario
      } else {
        setMessageSent({ text: 'Failed to send message.', color: 'red' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessageSent({ text: 'Failed to send message.', color: 'red' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // En pantallas pequeñas, las columnas se apilan verticalmente
          sm: 'row',    // En pantallas medianas y grandes, las columnas están en fila
        },
        width: '100%',
        height: '100%', // Ajusta según sea necesario
        position: 'relative',
        mt: '10rem',
      }}
    >
      {/* Parte derecha con el logo */}
      <Box
        sx={{
          width: {
            xs: '100%', // Ocupa el 100% en pantallas pequeñas
            sm: '50%',  // Ocupa el 50% en pantallas medianas y grandes
          },
          display: 'flex',
          justifyContent: 'center', // Centra horizontalmente
          alignItems: 'center',     // Centra verticalmente
          backgroundColor: 'transparent', // Cuadro vacío
        }}
      >
        <Card elevation={0} sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            sx={{
              width: 'auto', // Ajusta el ancho automáticamente
              maxWidth: '100%', // Limita el ancho al 100% del contenedor
              maxHeight: '600px', // Ajusta la altura máxima si es necesario
              objectFit: 'contain', // Ajusta la imagen sin recortes
            }}
            image={logo}
            alt="francos services"
          />
        </Card>
      </Box>

      {/* Parte izquierda con texto y formulario */}
      <Box
        sx={{
          width: {
            xs: '90%',
            sm: '50%',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem',
        }}
      >
        <Paper
          sx={{
            flex: 1,
            backgroundColor: 'rgba(204, 204, 204, 0.05)',
            height: '100%',
            width: '80%',
            padding: '2rem',
          }}
        >
          <Typography variant="h5" textTransform={'uppercase'}
            sx={{ fontSize: 'clamp(0.7rem, 0.5vw, 1rem)', mb: '1rem' }}
          >
            Contact
          </Typography>
          <Typography variant="h5"
            sx={{ fontSize: 'clamp(1.4rem, 1.5vw, 2rem)', mb: '1rem' }}
          >
            Let's get in touch
          </Typography>

          {/* Formulario */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('name', { required: 'Name is required' })}
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name?.message}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#DAC5A7', // Color del borde al hacer hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#DAC5A7', // Color del borde cuando está enfocado
                  },
                },
                '& label.Mui-focused': {
                  color: '#DAC5A7', // Color del label cuando está enfocado
                },
              }}
            />
            <TextField
              {...register('email', { required: 'Email is required', pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' } })}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#DAC5A7', // Color del borde al hacer hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#DAC5A7', // Color del borde cuando está enfocado
                  },
                },
                '& label.Mui-focused': {
                  color: '#DAC5A7', // Color del label cuando está enfocado
                },
              }}
            />
            <TextField
              {...register('message', { required: 'Message is required' })}
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              error={!!errors.message}
              helperText={errors.message?.message}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#DAC5A7', // Color del borde al hacer hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#DAC5A7', // Color del borde cuando está enfocado
                  },
                },
                '& label.Mui-focused': {
                  color: '#DAC5A7', // Color del label cuando está enfocado
                },
              }}
            />
            <ThemeProvider theme={theme}>
              <Button
                type="submit"
                variant="contained"
                color="ochre"
                sx={{
                  mt: '1rem',
                  width: '100%',  // Hace que el botón abarque todo el ancho del contenedor padre
                  textAlign: 'center',  // Asegura que el texto esté centrado
                  padding: '1rem',  // Añade un poco de padding para hacer el botón más grande
                }}
              >
                Send Message
              </Button>
            </ThemeProvider>
          </form>
          {messageSent && (
            <Typography variant="h6" sx={{ mt: '1rem', color: messageSent.color, textAlign: 'center' }}>
              {messageSent.text}
            </Typography>
          )}
        </Paper>

        {/* Sección de botones sociales */}
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'transparent',
            height: 'auto',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '3rem',
          }}
        >
          <SocialButtons />
        </Box>
      </Box>
    </Box>
  );
}

export default Contactus;

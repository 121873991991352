import * as React from 'react';
import { Box, Grid } from '@mui/material';
import Comments from './coments'; // Asegúrate de que este componente esté importado correctamente

export default function CommentsLayout() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // En pantallas pequeñas, las columnas se apilan verticalmente
          sm: 'row',    // En pantallas medianas y grandes, las columnas están en fila
        },
        width: '100%',
        height: '100%',
      }}
    >
      {/* Primera columna */}
      <Box
        sx={{
          flex: 1,          // Ocupa el 50% del ancho disponible en pantallas grandes
            // Espaciado interno
          width: {
            xs: '100%',   // Ocupa el 100% del ancho en pantallas pequeñas
            sm: '50%',    // Ocupa el 50% del ancho en pantallas medianas y grandes
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Comments
              title="Detailed Residential Cleaning"
              description="I am absolutely delighted with the cleaning service from Franco's Services. My house has never been so clean and tidy. The team was punctual, professional and very detailed. I will definitely recommend them to all my friends!"
              name="Andrea Lee"
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
          <Grid item xs={12}>
            <Comments
              title="Deep Cleaning"
              description="I hired Franco's Services for a deep cleaning before a big party, and they exceeded all my expectations. Every corner of my house was left spotless. I can't wait to hire them again!"
              name="Josh Kim"
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
          <Grid item xs={12}>
            <Comments
              title="Deep Cleaning"
              description="I am absolutely delighted with the cleaning service from Franco's Services. My house has never been so clean and tidy. The team was punctual, professional and very detailed. I will definitely recommend them to all my friends!"
              name="Catharine Davis"
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Segunda columna */}
      <Box
        sx={{
          flex: 1,          // Ocupa el 50% del ancho disponible en pantallas grandes
          padding: 2,       // Espaciado interno
          overflowY: 'auto', // Permite el desplazamiento vertical si hay muchos ítems
          width: {
            xs: '100%',   // Ocupa el 100% del ancho en pantallas pequeñas
            sm: '50%',    // Ocupa el 50% del ancho en pantallas medianas y grandes
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Comments
              title="Complete Double Moving Cleaning Service."
              description="Moving is stressful, but the team at Franco's Services made part of the process much easier. They left our old house spotless and thoroughly cleaned our new house before we moved in. Excellent service!"
              name="Carlos M."
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
          <Grid item xs={12}>
            <Comments
              title="Commercial Cleaning"
              description="Office cleaning from Franco's Services has been a blessing to our business. Our workspace is always sparkling clean and ready to welcome our clients. The quality of service is second to none."
              name="Andreas Thompson"
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
          <Grid item xs={12}>
            <Comments
              title="Airbnb Cleaning"
              description="As an Airbnb host, I need my property to always be spotless. Since working with Franco's Services, the cleanliness has been exceptional. The team is punctual and detail-oriented, and my guests always comment on how clean everything is. I recommend their services to everyone! all the hosts!"
              name="Liam Chen"
              status="Satisfied customer"
              avatarSrc="/path/to/avatar.jpg"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

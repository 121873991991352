import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system';
import { Paper } from '@mui/material';
import CButton from './circular';

function StepSec({ number }) {
  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 'auto',  // Ajusta según sea necesario
      position: 'relative',
    }}
    >
      {/* Parte izquierda con texto */}
      <Box
        sx={{
          width:{
            sm: '90%',
            md: '40%',
          }, // Ocupa el 40% del ancho
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
        }}
      >
        <Paper
        sx={{
          flex: 1,
          backgroundColor: alpha('#CCCCCC', 0.05),
          height: '100%',
          width:'80%',
          display: 'flex',
          flexDirection: 'column',
          
          justifyContent: 'center',
          padding: '1rem',
          position: 'relative',
        }}
      >

        <Typography variant="subtitle1" textTransform={'uppercase'}
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
        We need a plan
        </Typography>
        <Typography variant="h5" textTransform={'uppercase'}
        x={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
        >
        Planning  & Programming
        </Typography>
        <Typography variant="subtitle1"
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
        After accepting our quote, we coordinate with you to schedule a date and time that suits your convenience. Our team makes sure everything is prepared for uninterrupted cleaning.
        </Typography>
        
      </Paper>
      </Box>

      {/* Parte central con línea y número */}
      <Box
        sx={{
          display: 'flex',
          
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width:{
            sm: '10%',
            md: '20%',
          },
          height: '15rem', // Ocupa todo el alto del contenedor padre
        }}
      >
        {/* Línea superior */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea

            
          }}
        />
      
        {/* Número en el centro */}
        <Box>
        <CButton>
        <Typography
          variant="h4"
          sx={{
            zIndex: 1, // Asegura que el número esté encima de la línea
            
            padding: '0 0.5rem',
            margin: 'auto', // Centra el número verticalmente
          }}
        >
          {number}
        </Typography>
        </CButton>
        </Box>
        {/* Línea inferior */}
        <Box
          sx={{
            height: '100%',
            flex: 1,
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea
            
          }}
        />
      </Box>

      {/* Parte derecha vacía */}
      <Box
        sx={{
          width: '40%', // Ocupa el 40% del ancho
          backgroundColor: 'transparent', // Cuadro vacío
          height: '100%',
          flex: {
            sm: '0',
            md: '1',
          },
        }}
      />
    </Box>
  );
}

export default StepSec;

import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import { alpha } from '@mui/material';

export default function Comments( {title, description, name, status, avatarSrc } ) {
    return (
                <Box
            sx={{
              width:{
                sm: '100%',
                
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
              
            }}
          >
           <Paper
            sx={{
              flex: 1,
              backgroundColor: alpha('#CCCCCC', 0.05),
              height: '100%',
              width:'80%',
              display: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
              
            }}
          >
            <Typography variant="h5" textTransform={'uppercase'} 
            sx={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
            >
            {title}
            </Typography>
            <Typography variant="subtitle1"
            sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
            >
             {description}
            </Typography>

            <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%', // Ajusta según sea necesario
            position: 'relative',
            
          }}
          >
            <Box
            sx={{
                width:'20%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                
                
              }}
            >
                <Avatar alt= {name} src={avatarSrc} />
            </Box>
            <Box
            sx={{
                
                display: 'block',
                alignItems: 'center',

            }} 
            >
                <Typography variant="h5" textTransform={'uppercase'} 
        sx={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
        >
        {name}
        </Typography>
        <Typography variant="subtitle1"
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
         {status}
        </Typography>
                
            </Box>
          </Box>
          

          </Paper>
          </Box>
          
                

  );
}

import * as React from 'react';
import  { Box, Container } from '@mui/material';
import logo from '../img/logo1.png';
import { alpha, Link, CardMedia, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FacebookLogo, TiktokLogo, InstagramLogo, WhatsappLogo, ArrowUp } from "@phosphor-icons/react";
import styled from 'styled-components';
import CButton from './circular';
const MiLink = styled(Link)`

    color: #DAC5A7;
    opacity: 0.6;
    &:hover {
    color: #DAC5A7;
    cursor: pointer;
    opacity: 1;
    text-decoration: none;
    }
`;



function Foother () {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          
          behavior: 'smooth', // Desplazamiento suave
        });
      };
    
    return (
        <Container  sx={{ bgcolor:  alpha('#DAC5A7', 0.05), p: '2rem'}} >
            
            <Box sx={{ color:  'inherit'}} >
            
            <Box
                sx={{
                color:  'inherit',
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                m: 1,
                width: "48%",
                height: "100%",
                },
                }}
                >
                <Box >
                <Box
  sx={{
    p: '0.5rem',
    m: '1rem',
    // Tamaño del contenedor en función del tamaño de la pantalla
    width: { xs: '50%', sm: '30%', md: '20%' }, // Ajusta los porcentajes según tus necesidades
    height: { xs: 'auto', sm: '40%' }, // Permite que la altura sea automática en pantallas pequeñas
    display: 'flex',
    justifyContent: 'center', // Centra el contenido horizontalmente
    alignItems: 'center', // Centra el contenido verticalmente
  }}
>
  <Card>
    <CardMedia
      component="img"
      image={logo}
      alt="francos services"
      sx={{
        width: '100%', // Asegura que la imagen ocupa el 100% del contenedor
        height: 'auto', // Mantiene la proporción de la imagen
      }}
    />
  </Card>
</Box>
 
                    <Box m='1rem' sx={{display: 'flex',alignItems: 'center', }}> 
                    <CButton component="a" href="https://www.instagram.com/francosservices11/" target="_blank" rel="noopener noreferrer"> 
                    <InstagramLogo p='0.5rem' size={23}/> 
                        </CButton> 
                        <Typography variant="body1" sx={{ ml: '0.5rem' }}>
                        <MiLink href="https://www.instagram.com/francosservices11/" target="_blank" underline="none" color="inherit">
                        INSTAGRAM
                                </MiLink>
                            
                        </Typography> 
                        </Box>
                    <Box m='1rem' sx={{display: 'flex',alignItems: 'center', }}> 
                    <CButton component="a" href="https://www.tiktok.com/@francosservices11?lang=en" target="_blank" rel="noopener noreferrer"> 
                    <TiktokLogo p='0.5rem' size={23}/> 
                        </CButton>
                        <Typography variant="body1" sx={{ ml: '0.5rem' }}>
                        <MiLink href="https://www.tiktok.com/@francosservices11?lang=en" target="_blank" underline="none" color="inherit">
                        TIKTOK
                                </MiLink>
                            
                        </Typography> 
                        </Box>
                    <Box m='1rem' sx={{display: 'flex',alignItems: 'center', }}>
                    <CButton component="a" href="https://www.facebook.com/profile.php?id=61563120154820" target="_blank" rel="noopener noreferrer"> 
                    <FacebookLogo p='0.5rem'size={23}/> 
                        </CButton>
                        <Typography variant="body1" sx={{ ml: '0.5rem' }}>
                        <MiLink href="https://www.facebook.com/profile.php?id=61563120154820" target="_blank" underline="none" color="inherit">
                        FACEBOOK
                                </MiLink>
                            
                        </Typography>
                        </Box>
                        <Box m='1rem' sx={{ display: 'flex', alignItems: 'center' }}>
                            <CButton component="a" href="https://wa.me/+17372984421" target="_blank" rel="noopener noreferrer"> 
                            
                                <WhatsappLogo p='0.5rem' size={23}/>
                            </CButton> 
                            <Typography variant="body1" sx={{ ml: '0.5rem' }}>
                                <MiLink href="https://wa.me/+17372984421" target="_blank" underline="none" color="inherit">
                                WHATSAPP
                                </MiLink>
                            </Typography>  
                        </Box>
                    </Box>
                <Box>  
                <Box m='1rem'> <Typography variant="h6">  Pages </Typography> </Box>
                <Box m='1rem'> <MiLink href="/" underline="none" color="inherit"> Home </MiLink> </Box>
                <Box m='1rem'> <MiLink href="/services" underline="none" color="inherit"> Services </MiLink> </Box>
                <Box m='1rem'> <MiLink href="/about" underline="none" color="inherit"> About </MiLink> </Box>
                <Box m='1rem'> <MiLink href="/faq" underline="none" color="inherit"> FAQ </MiLink> </Box>
                <Box m='1rem'> <MiLink href="/contactus" underline="none" color="inherit"> Contact </MiLink> </Box>
                </Box>
                
    </Box>
    
            </Box>

            <Box sx={{display: 'flex', p: '2rem' }}>
                <Box sx={{ display: 'flex', 
                  width: {
                
                    sm: '70%',  // Ancho estándar para pantallas medianas
                    md: '80%',  // Ancho más grande para pantallas grandes
                  }
                  , justifyContent: 'center', alignItems: 'center'}}> 
                    <Typography> ©Made by Franco's Services. </Typography>
                </Box>
                <Box sx={{ 
                  width: {
                
                    sm: '30%',  // Ancho estándar para pantallas medianas
                    md: '20%',  // Ancho más grande para pantallas grandes
                  }
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} > 
                <Typography variant="body1" sx={{ mr: '1rem' }}>
                <MiLink onClick={scrollToTop} underline="none" color="inherit">
                                TO TOP
                </MiLink>
                
                </Typography>
                    <CButton onClick={scrollToTop} color = 'inherit' > <ArrowUp p='0.5rem'/> </CButton></Box>
                </Box>
            </Box>
        </Container>
    );
}
export default Foother;
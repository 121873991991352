import React from 'react';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

const CButton = ({ children, ...props }) => {
  return (
    <Box
      component="a" // Hace que el Box actúe como un enlace
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%', // Hace el componente circular
        border: '2px solid rgba(218, 197, 167, 0.15)', // Borde del componente
        backgroundColor: alpha('#CCCCCC', 0.05), // Color de fondo con opacidad
        width: { sm: '2rem', md: '3rem' }, // Tamaño del componente en pantallas pequeñas
        height: { sm: '2rem', md: '3rem' }, // Tamaño del componente en pantallas pequeñas
        textDecoration: 'none', // Asegura que no haya subrayado
        '&:hover': {
          backgroundColor: alpha('#CCCCCC', 0.1), // Cambio de fondo en hover
        },
        // Mantener el estilo de texto (color) cuando es un enlace
        color: 'inherit',
      }}
    >
      {children}
    </Box>
  );
};

export default CButton;

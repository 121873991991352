import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import logo from '../img/logo1.png';
import Button from '@mui/material/Button';
import { CardMedia, Card } from '@mui/material';
import { alpha } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    ochre: {
      main: '#DAC5A7',
      light: '#E9DB5D',
      dark: '#EDD6B6',
      contrastText: '#242105',
    },
  },
  typography: {
    fontFamily: 'SatoshiL, Arial',
  },
});

const pages = ['SERVICES', 'ABOUT', 'FAQ', 'BLOG'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    if (path) {
      navigate(path);
    }
  };

  React.useEffect(() => {
    // Scroll al top al montar el componente
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <AppBar elevation={0} sx={{ display: 'flex', pt: '2rem', bgcolor: 'transparent', boxShadow: 'none', border: 'none' }}>
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Toolbar disableGutters sx={{ bgcolor: alpha('#CCCCCC', 0.05), border: '2px solid rgba(218, 197, 167, 0.15)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Card elevation={0} sx={{ maxWidth: "1rm" }} onClick={() => navigate('/')}>
            <CardMedia
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '40px',
                objectFit: 'cover',
              }}
              image={logo}
              alt="francos services"
            />
          </Card>

          <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleCloseNavMenu(`/${page.toLowerCase()}`)}
                sx={{ my: 2, color: '#DAC5A7', display: 'flex',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '0%',
                    height: '2px',
                    backgroundColor: '#DAC5A7',
                    bottom: 0,
                    left: 0,
                    transition: 'width 0.3s ease-in-out',
                  },
                  '&:hover::after': {
                    width: '100%',
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box >
            <ThemeProvider theme={theme}>
              <Button
                sx={{
                  fontSize: {
                    sm: '0.1rem',
                    md: '0.9rem',
                  },
                  p: 0.2,
                  m: 1,
                  width: {
                    sm: '20px',
                    md: '100px',
                  },
                }}
                variant="contained" color="ochre"
                onClick={() => navigate('/contactus')}
              >
                LET'S TALK
              </Button>
            </ThemeProvider>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(`/${page.toLowerCase()}`)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;

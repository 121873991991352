import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    ochre: {
      main: '#DAC5A7',
      light: '#E9DB5D',
      dark: '#EDD6B6',
      contrastText: '#242105',
    },},  });

const Error404 = () => {
    return (
      <Box sx={{ textAlign: 'center', mb: '2rem', mt: '10rem' }}>
                <Paper sx={{ p: '1rem', mt: '1rem', maxWidth: '600px', mx: 'auto' }}>
          <Typography variant="h6" sx={{ fontSize: 'clamp(1rem, 1.4vw, 1rem)' }}>
           404
          </Typography>
        </Paper>
        <Typography variant="h1" sx={{ fontSize: 'clamp(2rem, 5vw, 3rem)' }}>
        Page Not Found
        </Typography>
         <ThemeProvider theme={theme}>
            <Button
              type="submit"
              variant="contained"
              color="ochre" 
              sx={{ mt: '1rem',
                width: '40%',  // Hace que el botón abarque todo el ancho del contenedor padre
                textAlign: 'center',  // Asegura que el texto esté centrado
                padding: '1rem',  // Añade un poco de padding para hacer el botón más grande

               }}
            >
              Back Home
            </Button>
            </ThemeProvider>
      </Box>
    );
  };
  
  export default Error404;
  
import React from 'react';
import Box from '@mui/material/Box';
import { CardMedia, Card, Typography, } from '@mui/material';
import { alpha, Container } from '@mui/system';
import { Paper,  } from '@mui/material';
import CButton from './circular';
import { ArrowDown } from "@phosphor-icons/react";
import logo from '../img/logo.png';
import SocialButtons from './socialbutton.js';
import Marquee from "./cta";


function Aboutus ({  title, description }) {
return (
  <Container>
    <Box
    sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // En pantallas pequeñas, las columnas se apilan verticalmente
          sm: 'row',    // En pantallas medianas y grandes, las columnas están en fila
        },
        
        width: '100%',
        height: '100%', // Ajusta según sea necesario
        position: 'relative',
        mt: '10rem'
      }}
    >
              {/* Parte derecha con el logo */}
      <Box
      
        sx={{
            
          width: {
            sm: '100%', // Ocupa el 100% en pantallas pequeñas
            md: '50%',  // Ocupa el 50% en pantallas medianas y grandes
          },
          backgroundColor: 'transparent', // Cuadro vacío
         
          position: { 
            sm: 'static',  // No es sticky en pantallas pequeñas
            md: 'sticky',  // Sticky en pantallas medianas y grandes
          },
          top: 0, // Sticky desde la parte superior
          alignSelf: 'flex-start',
        }}
      >
             <Card elevation={0} >
                    <CardMedia 
                        component="img"
                        width= '100%'
                        height= 'auto'
                        maxHeight= '100px' // Ajusta la altura máxima si es necesario
                        objectFit= 'cover'
                        image={ logo }
                        alt="francos services"
                    /></Card> 
      </Box>
     
            {/* Parte izquierda con texto */}
            <Box
        sx={{
          width:{
            sm: '90%',
            md: '50%',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem',
          
        }}
      >
                <Box sx={{  }} >
                <Typography variant="h1"
                sx={{
                    fontSize: 'clamp(2rem, 4vw, 5rem)',
                  }}
                >Housekeeping</Typography>
            </Box>
            <Box sx={{ }} >
                <Box m='1rem' sx={{ display: 'flex', alignItems: 'center' }} > <CButton color = 'inherit' > <ArrowDown p='0.5rem'/> </CButton>
                <Typography sx={{ ml: '0.5rem' }}> About us </Typography>
                </Box></Box>
       <Paper
        sx={{
          flex: 1,
          backgroundColor: alpha('#CCCCCC', 0.05),
          height: '100%',
          width:'80%',
          display: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          
        }}
      >

        <Typography variant="h5" textTransform={'uppercase'}
        sx={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
        >
        "Superior, personalized cleaning services that fit your needs and exceed your expectations."
        </Typography>
        <Typography variant="subtitle1"
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
         We are dedicated to providing superior, high-quality cleaning services to our esteemed clients throughout Washington, DC, offering our solutions at the most competitive rates on the market.<br/><br/>

We recognize the value of your hard-earned money and ensure that you don't have to spend it on a cleaning service that doesn't meet your exacting standards.<br/><br/>

We are committed to efficiency and thoroughness, avoiding any shortcuts and ensuring that no area is left unattended, no matter how difficult it may be to access.<br/><br/>

We fully trust in the excellence of our service and that is why we uphold our promise:<br/><br/>

<strong> "If you are not satisfied, neither are we."</strong><br/><br/>

We guarantee total customer satisfaction, and if we fail to meet your expectations, we will come back and fix any issues at no additional cost.
</Typography> 
      </Paper>
      <Box
        sx={{
          width: '80%', // Ocupa el 40% del ancho
          backgroundColor: 'transparent', // Cuadro vacío
          height: 'auto',
          
        }}
      >
      <Card>
                    <CardMedia 
                        component="img"
                        width= '100%'
                        height= 'auto'
                        maxHeight= '100px' // Ajusta la altura máxima si es necesario
                        objectFit= 'cover'
                        image={ logo }
                        alt="francos services"
                    /></Card> 
                    </Box>
       <Box  sx={{
          
          display: 'flex',
          flexWrap: 'wrap',  // Permite que los botones se dispongan en filas múltiples si es necesario
          justifyContent: 'center',  // Centra los botones horizontalmente
          gap: '3rem',  // Añade espacio entre los botones
          }}>

       <SocialButtons></SocialButtons>
       </Box>
      </Box>

    </Box>
    <Box sx={{ display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                bgcolor:  alpha('#CCCCCC', 0.05),
                width:'100%',
                border: '2px solid rgba(218, 197, 167, 0.15)'}} >
                <Marquee/>
            </Box>
    </Container>
  );
}

export default Aboutus;

import React from 'react';
import { Grid, Box } from '@mui/material';
import BlogHeader from './BlogHeader';
import BlogCard from './BlogCard';
import blogImage1 from '../img/blog(1).png';
import blogImage2 from '../img/blog(2).png';
import blogImage3 from '../img/blog(3).jpg';
import blogImage4 from '../img/blog(4).png';
import blogImage5 from '../img/blog(5).png';
import blogImage6 from '../img/blog(6).png';


const Blog = () => {
  const blogPosts = [
    { image: blogImage1, title: 'Regular cleaning', description: 'Maintain the cleanliness and hygiene of your space with regular service. Adapted to your schedule, we provide consistent quality, ensuring your environment is always welcoming.' },
    { image: blogImage2, title: 'Post construction cleaning', description: 'Once the builders leave, our post-construction team comes in. We remove dust, debris, and leftover materials, leaving your new or renovated space move-in ready.' },
    { image: blogImage3, title: 'Deep cleaning', description: 'Revitalize your property with a deep cleaning. We focus on hard-to-reach areas and leave no corner uncleaned, returning freshness and shine to each room.' },
    { image: blogImage4, title: 'Exit cleaning', description: 'Simplify your move with complete moving services. Leave your space spotless for the next occupants or make sure your new home is clean and comfortable from the start.' },
    { image: blogImage5, title: 'Airbnb Cleaning', description: 'Prepare your property for the next guests with our Airbnb cleaning service.  We meticulously clean each area to ensure a spotless and welcoming space for each arrival.' },
    { image: blogImage6, title: 'Commercial Cleaning', description: "Boost your business's image with professional cleaning. Our meticulous approach ensures a clean, healthy, and inviting workspace for employees and customers alike." },
  ];

  return (
    <Box sx={{ width: '100%', padding: '2rem' }}>
      <BlogHeader />
      <Grid container spacing={3}>
        {blogPosts.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <BlogCard image={post.image} title={post.title} description={post.description} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blog;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system';
import { Paper,  } from '@mui/material';
import CButton from './circular';

function StepSec3({ number, title, description }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%', // Ajusta según sea necesario
        position: 'relative',
      }}
    >
              {/* Parte derecha vacía */}
      <Box
        sx={{
          width: '40%', // Ocupa el 40% del ancho
          backgroundColor: 'transparent', // Cuadro vacío
          height: '100%',
          flex: {
            xs: '0',
            sm: '1',
          },
        }}
      />
      {/* Parte central con línea y número */}
      <Box
        sx={{
          width: {
            sm: '10%',
            md: '20%',
          },
          display: 'flex',
          height: '15rem',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {/* Línea superior */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea
          }}
        />

        {/* Número en el centro */}
        <CButton>
        <Typography
          variant="h4"
          sx={{
            zIndex: 1, // Asegura que el número esté encima de la línea
            
            padding: '0 0.5rem',
            margin: 'auto', // Centra el número verticalmente
          }}
        >
          {number}
        </Typography>
        </CButton>
        {/* Línea inferior */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            width: '2px', // Ancho de la línea
            backgroundColor: alpha('#CCCCCC', 0.5), // Color de la línea
          }}
        />
      </Box>


            {/* Parte izquierda con texto */}
            <Box
        sx={{
          width:{
            sm: '90%',
            md: '40%',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          
        }}
      >
       <Paper
        sx={{
          flex: 1,
          backgroundColor: alpha('#CCCCCC', 0.05),
          height: '100%',
          width:'80%',
          display: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          
        }}
      >
        <Typography variant="h5" textTransform={'uppercase'}
        sx={{  fontSize: 'clamp(1.4rem, 1.5vw, 2rem)',}}
        >
        {title}
        </Typography>
        <Typography variant="subtitle1"
        sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
        >
        {description}
        </Typography>
        
      </Paper>
      </Box>

    </Box>
  );
}

export default StepSec3;

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { FacebookLogo, TiktokLogo, InstagramLogo, WhatsappLogo, } from "@phosphor-icons/react";
import CButton from './circular';


export default function Rabout() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // En pantallas pequeñas, apilar verticalmente
          sm: 'row'    // En pantallas medianas y grandes, alinear en fila
        },
        width: '100%',
        height: '100%', // Ocupa toda la altura de la vista
        padding: 2,
      }}
    >
         {/* Lado Izquierdo con la imagen */}
      <Box
        sx={{
          flex: 1,              // Ocupa el 50% del ancho disponible
          display: 'block',
          alignItems: 'center',
          
          justifyContent: 'center',
          
        }}
      >
        <Typography variant="h5" 
        sx={{
      
          fontSize: 'clamp(3rem, 5vw, 2rem)',
          }}
        >
        Cleaning that leaves
        </Typography>
        <Typography variant="h5" 
        sx={{
            fontStyle: 'italic',
            fontSize: 'clamp(3rem, 5vw, 2rem)',
          }}
        >
        a lasting impression!
        </Typography>
      </Box>
    
      {/* Lado derecho con el título */}
      <Box
        sx={{
          flex: 1,              // Ocupa el 50% del ancho disponible
          display: 'block',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
      >
        <Typography variant="subtitle1" 
        sx={{
            fontSize: 'clamp(1.3rem, 1vw, 1rem)',
          }}
        >
        Hello, we are Franco's Services, specialists in premium cleaning services in Washington, DC. We are passionate about providing unique and effective cleaning solutions for our clients, and we bring a personal touch to every project. Let's work together to make your vision of a sparkling clean space a reality!
        </Typography>
        <Box
        sx={{
        display: 'flex',
        flex: 1,
        gap: '0.5rem'
        }}
        >
        <CButton component="a" href="https://www.instagram.com/francosservices11/" target="_blank" rel="noopener noreferrer"> 
        <InstagramLogo p='0.5rem' size={23}/> </CButton>
        <CButton  component="a" href="https://www.tiktok.com/@francosservices11?lang=en" target="_blank" rel="noopener noreferrer"> 
        <TiktokLogo p='0.5rem' size={23}/> </CButton>
        <CButton component="a" href="https://www.facebook.com/profile.php?id=61563120154820" target="_blank" rel="noopener noreferrer">
         <FacebookLogo p='0.5rem'size={23}/> </CButton>
        <CButton  component="a" href="https://wa.me/+17372984421" target="_blank" rel="noopener noreferrer"> 
        <WhatsappLogo p='0.5rem'size={23}/></CButton>
        </Box>
      </Box>

    </Box>
  );
}

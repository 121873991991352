import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResponsiveAppBar from './components/head';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Foother from './components/fooder';
import Main from './components/main';
import Services from './components/services';
import Aboutus from './components/aboutus';
import Faq from './components/faq.js';
import Contactus from './components/contact.js';
import Blog from './components/Blog.js';
import Error404 from './components/Error404.js';
import SEO from './components/SEO.js';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: "#DAC5A7"
    }
  },
  typography: {
    fontFamily: 'SatoshiL, Arial',
    allVariants: {
      color: "#DAC5A7"
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <SEO 
        title="Francos Services" 
        description="The best cleaning service in Maryland, Virginia & DC!" 
        image="/portada.png"
      />
      <Router>
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
      <Foother />
    </ThemeProvider>
  );
}

export default App;

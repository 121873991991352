import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { Dot } from "@phosphor-icons/react";

export default function CustomText({ text1, texto2 }) {
  return (
    <Box sx={{ mt: '1rem' }}>
      <Typography variant="body1" gutterBottom
      sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
      >
        {text1}
      </Typography>
      <List>
        <ListItem >
          <Dot size={24} />
          <ListItemText primary={texto2} 
          sx={{  fontSize: 'clamp(1rem, 1vw, 2rem)',}}
          />
        </ListItem>
      </List>
    </Box>
  );
}
